import LottoProvider from "@/resources/lotto_provider"
const lottoService = new LottoProvider()

export default {
  name: "LottoPlay",
  data() {
    return {
      isViewInit: false,
      lottoTypeId: null,
      lottoRoundId: null,
      isClosed: false,
      isLoading: false,
      lottoType: {},
      // lottoPay: {},
      // lottoPays: [],
      lottoRound: {},
      // categories: [], // category pay
      betLimits: [],
      totalBuyAmounts: [],
      totalBuyAmountMap: {},
      allTotalBuyAmounts: [],
      allTotalBuyAmountMap: {},
      betReceiveNumbers: [],
      betReceiveNumberMap: {},
      betReceiveCategories: [],
      betReceiveCategoryMap: {},
      lottoDisplayResultCategoryCodes: ["MAIN", "BACK_3_TOP", "BACK_2_BOTTOM"],
      number: "",
      numberTmps: [],
      numberList: [],
      numberGroupList: [],
      runningNumberVersion: 0,
      note: "",
      mainTabIndex: 0,
      buyAmount1: null,
      buyAmount2: null,
      huayTabIndex: "two",
      latestBills: [],
      currentLatestBill: {},
      backgroundColor: "info",
      winNumber: {}
    }
  },
  computed: {
    themeColor() {
      var color = {
        background: this.lottoType.betBackgroundColor || "#cde1ec",
        font: this.lottoType.betFontColor || "#043653",
        border: this.lottoType.betBorderColor || "#07689f"
      }
      return color
    },
    categories() {
      return this.$store.getters["lotto/currentLottoCategoryPays"]
    },
    lottoPays() {
      return this.$store.state.lotto.lottoPays
    },
    lottoPayId: {
      get() {
        return this.$store.state.lotto.currentLottoPayId
      },
      set: function (value) {
        this.$store.commit("lotto/setCurrentLottoPayId", value)
      }
    },
    maxLength() {
      if (this.huayTabIndex == "two") {
        return 2
      } else if (["three", "six"].indexOf(this.huayTabIndex) != -1) {
        return 3
      } else {
        return 1
      }
    },
    creditBalance() {
      return parseFloat(this.$store.state.auth.user.userData.creditBalance)
    },
    closeDiffTime() {
      return (
        (this.$date(this.lottoRound.closeTime).unix() - this.$date().unix()) *
        1000
      )
    },
    totalGroupBuyAmount() {
      return this.numberGroupList.reduce(
        (a, b) =>
          a + b.numberList.length * ((b.buyAmount1 || 0) + (b.buyAmount2 || 0)),
        0
      )
    },
    totalBuyAmount() {
      return this.numberList.reduce((a, b) => a + b["buyAmount"], 0)
    },
    totalCommission() {
      return this.numberList.reduce(
        (a, b) => a + parseFloat(b["commission"]),
        0
      )
    },
    totalBuyAmountNet() {
      return this.numberList.reduce(
        (a, b) => a + b["buyAmount"] - parseFloat(b["commission"]),
        0
      )
    },
    totalPayAmount() {
      return this.numberList.reduce(
        (a, b) => a + b["buyAmount"] * parseFloat(b["payRate"]),
        0
      )
    },
    isCreditBalanceEnough() {
      return this.creditBalance > this.totalGroupBuyAmount
    },
    categoryLimit() {
      return this.categories.filter((item) => {
        return this.betReceiveNumbers.find(
          (item2) =>
            item2.amount == 0 && item2.huayCategoryId == item.huayCategoryId
        )
      })
    },
    isBuyAmount1Show() {
      var cateCode = ""
      switch (this.huayTabIndex) {
        case "win":
        case "two":
        case "nineteen": {
          cateCode = "BACK_2_TOP"
          break
        }
        case "three":
        case "six": {
          cateCode = "BACK_3_TOP"
          break
        }
        case "run": {
          cateCode = "RUN_BACK_TOP"
          break
        }
      }

      return this.findCategoryByCode(cateCode) != null
    },
    isBuyAmount2Show() {
      if (this.huayTabIndex == "six") return false
      var cateCode = ""
      switch (this.huayTabIndex) {
        case "win":
        case "two":
        case "nineteen": {
          cateCode = "BACK_2_BOTTOM"
          break
        }
        case "three": {
          cateCode = "BACK_3_TOP_CROSS"
          break
        }
        case "run": {
          cateCode = "RUN_BACK_BOTTOM"
          break
        }
      }
      return this.findCategoryByCode(cateCode) != null
    }
  },
  watch: {
    async isClosed() {
      if (this.isClosed) {
        await this.$swal({
          title: "ปิดรับแทง",
          icon: "warning",
          confirmButtonText: "ตกลง"
        })

        this.$router.replace("/bet")
      }
    }
  },
  methods: {
    resetWinNumer() {
      this.winNumber = {
        winNumber: "win_2",
        winDouble: "win_no_double",
        winPadNumber0: false,
        winPadNumber1: false,
        winPadNumber2: false,
        winPadNumber3: false,
        winPadNumber4: false,
        winPadNumber5: false,
        winPadNumber6: false,
        winPadNumber7: false,
        winPadNumber8: false,
        winPadNumber9: false
      }
    },
    async calWinNumber() {
      let winPadPicked = ""
      if (this.winNumber.winPadNumber0) winPadPicked += "0"
      if (this.winNumber.winPadNumber1) winPadPicked += "1"
      if (this.winNumber.winPadNumber2) winPadPicked += "2"
      if (this.winNumber.winPadNumber3) winPadPicked += "3"
      if (this.winNumber.winPadNumber4) winPadPicked += "4"
      if (this.winNumber.winPadNumber5) winPadPicked += "5"
      if (this.winNumber.winPadNumber6) winPadPicked += "6"
      if (this.winNumber.winPadNumber7) winPadPicked += "7"
      if (this.winNumber.winPadNumber8) winPadPicked += "8"
      if (this.winNumber.winPadNumber9) winPadPicked += "9"

      let minLen = 3
      if (this.winNumber.winNumber == "win_2") {
        minLen = 2
      }

      if (winPadPicked.length < minLen || winPadPicked.length > 7) {
        await this.$swal({
          icon: "warning",
          text: `กรุณาเลือกตัวเลขที่ต้องการจับวิน ${minLen} - 7 ตัวเลข`,
          confirmButtonText: "ตกลง"
        })
        return
      }

      let winPadArr = []
      if (this.winNumber.winNumber == "win_2") {
        for (let i = 0; i < winPadPicked.length; i++) {
          for (let j = 0; j < winPadPicked.length; j++) {
            if (
              winPadPicked[i] == winPadPicked[j] &&
              this.winNumber.winDouble == "win_no_double"
            ) {
              continue
            } else if (
              winPadArr.find(
                (item) => item == winPadPicked[j] + winPadPicked[i]
              )
            ) {
              continue
            }

            winPadArr.push(winPadPicked[i] + winPadPicked[j])
          }
        }
      } else {
        for (let i = 0; i < winPadPicked.length; i++) {
          for (let j = 0; j < winPadPicked.length; j++) {
            for (let k = 0; k < winPadPicked.length; k++) {
              let winPadNum =
                winPadPicked[i] + winPadPicked[j] + winPadPicked[k]

              if (
                (winPadPicked[i] == winPadPicked[j] ||
                  winPadPicked[i] == winPadPicked[k] ||
                  winPadPicked[j] == winPadPicked[k]) &&
                this.winNumber.winDouble == "win_no_double"
              ) {
                continue
              } else if (
                winPadArr.find((item) =>
                  this.generateUniqueReverse(winPadNum).find(
                    (item2) => item2 == item
                  )
                )
              ) {
                continue
              }

              winPadArr.push(winPadNum)
            }
          }
        }
      }

      this.numberTmps = [...new Set(winPadArr)]
    },
    getBetLimitDisplayByCategory(cate) {
      return (
        this.betLimits
          .filter((ele) => ele.huayCategoryId == cate.huayCategoryId)
          .map((ele) => ele.number)
          .join(", ") || "-"
      )
    },
    getCloseNumberDisplayByCategory(cate) {
      // var recCateAmt =
      //   this.betReceiveCategories.find(
      //     (ele) => ele.huayCategoryId == cate.huayCategoryId
      //   )?.amount || 0
      var recCateAmt = this.betReceiveCategoryMap[cate.huayCategoryId] || 0
      if (recCateAmt == 0) {
        return "ปิดรับทั้งหมด"
      }

      var nums = []
      for (var i = 0; i < 10 ** cate.huayCategoryNumberLength; i++) {
        var num = i.toString().padStart(cate.huayCategoryNumberLength, "0")
        var crossNums = [num]
        if (cate.huayCategoryIsCross) {
          crossNums = this.generateUniqueReverse(num)
        }

        var minCrossNum = this.getMin(crossNums)

        var maxAllBuyAmount = recCateAmt

        var recNumAmt =
          this.betReceiveNumberMap[`${cate.huayCategoryId}_${minCrossNum}`]
        if (recNumAmt != undefined && recNumAmt < maxAllBuyAmount) {
          maxAllBuyAmount = recNumAmt
        }

        var maxBuyAmount = cate.userHuayCategoryBuyMaxPerNumber
        // var allTotalBuyAmount =
        //   this.allTotalBuyAmountMap[`${cate.huayCategoryId}_${num}`] || 0
        var allTotalBuyAmount = 0
        crossNums.forEach((cn) => {
          allTotalBuyAmount +=
            this.allTotalBuyAmountMap[`${cate.huayCategoryId}_${cn}`] || 0
        })

        var totalBuyAmount =
          this.totalBuyAmountMap[`${cate.huayCategoryId}_${num}`] || 0
        if (
          allTotalBuyAmount >= maxAllBuyAmount ||
          totalBuyAmount >= maxBuyAmount ||
          maxAllBuyAmount - allTotalBuyAmount < cate.userHuayCategoryBuyMin ||
          maxBuyAmount - totalBuyAmount < cate.userHuayCategoryBuyMin
        ) {
          nums.push(minCrossNum)
        }
      }

      return Array.from(new Set(nums)).join(", ") || "-"
    },
    async toggleBillExpanded(bill) {
      this.currentLatestBill = bill
      // bill.expanded = !bill.expanded;
      // if (bill.expanded) {
      this.swal.processing()
      const res = await lottoService.getBillDetail(bill.id)
      this.swal.close()
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        bill.items = res.result.billItems
        bill.cancelable = res.result.cancelable
        bill.cancelMinuteBeforeClose = res.result.cancelMinuteBeforeClose
        bill.cancelWithinMinuteAfterBet = res.result.cancelWithinMinuteAfterBet
        bill.numberOfCancelBill = res.result.numberOfCancelBill
        this.$bvModal.show("modal-bill")
      }
      // }
    },
    async getLottoRoundDetail(resolver) {
      this.swal.processing()

      // this.isLoading = true
      const res = await lottoService.getLottoPlayDetail(
        this.lottoRoundId,
        resolver
      )
      // this.isLoading = false

      if (res.error) {
        this.swal.swalError(
          res.error.message,
          function () {
            this.$router.replace("/bet")
          }.bind(this)
        )
      } else {
        if (
          res.result.huayType.image &&
          !res.result.huayType.image.includes("https")
        ) {
          res.result.huayType.image = require(`@/assets/images/lotto-type/${res.result.huayType.image}`)
        }
        this.backgroundColor = res.result.backgroundColor
        this.lottoType = res.result.huayType
        this.lottoRound = res.result.huayRound
        this.isViewInit = true
        this.betLimits = res.result.betLimits
        this.totalBuyAmounts = res.result.totalBuyAmounts
        var totBuyAmtMap = {}
        this.totalBuyAmounts.forEach((ele) => {
          totBuyAmtMap[`${ele.huayCategoryId}_${ele.number}`] =
            ele.totalBuyAmount
        })
        this.totalBuyAmountMap = totBuyAmtMap
        this.allTotalBuyAmounts = res.result.allTotalBuyAmounts
        var allTotBuyAmtMap = {}
        this.allTotalBuyAmounts.forEach((ele) => {
          allTotBuyAmtMap[`${ele.huayCategoryId}_${ele.number}`] =
            ele.totalBuyAmount
        })
        this.allTotalBuyAmountMap = allTotBuyAmtMap
        this.betReceiveNumbers = res.result.betReceiveNumbers
        var betRecNumMap = {}
        this.betReceiveNumbers.forEach((ele) => {
          betRecNumMap[`${ele.huayCategoryId}_${ele.number}`] = ele.amount
        })
        this.betReceiveNumberMap = betRecNumMap
        this.betReceiveCategories = res.result.betReceiveCategories
        var betRecCateMap = {}
        this.betReceiveCategories.forEach((ele) => {
          betRecCateMap[`${ele.huayCategoryId}`] = ele.amount
        })
        this.betReceiveCategoryMap = betRecCateMap
        this.latestBills = res.result.latestBills.map((ele) => {
          ele.expanded = false
          ele.items = []
          ele.cancelable = false
          ele.cancelMinuteBeforeClose = 0
          ele.cancelWithinMinuteAfterBet = 0
          ele.numberOfCancelBill = 0
          return ele
        })
        if (res.result.payRates) {
          res.result.payRates.forEach((cate) => {
            cate.active = false
            cate.code = cate.huayCategoryCode
            cate.name = cate.huayCategoryName
          })
          // this.categories = res.result.payRates;
          this.$store.commit("lotto/setLottoPays", res.result.huayPays)
          if (this.lottoPays.length > 0 && !this.lottoPayId) {
            this.$store.commit(
              "lotto/setCurrentLottoPayId",
              this.lottoPays[0].id
            )
          }
          this.$store.commit("lotto/setLottoCategoryPays", res.result.payRates)
          // if (this.lottoPayId) {
          //   this.$store.commit("lotto/setCurrentLottoCategoryPays",
          //   res.result.payRates.filter((ele) => ele.huayPayId == this.lottoPayId));
          // }
        }

        setInterval(
          function () {
            var diffTime =
              (this.$date(this.lottoRound.closeTime).unix() -
                this.$date().unix()) *
              1000
            if (this.isViewInit && diffTime <= 0) {
              this.isClosed = true
            }
          }.bind(this),
          3000
        )

        this.swal.close()
      }
    },
    async changeHuayTabIndex(ind) {
      if (this.numberTmps.length > 0) {
        let isConfirm = await this.swal.confirm(
          "ต้องการเปลี่ยน",
          "ข้อมูลที่ยังไม่บันทึกจะหายไป"
        )
        if (!isConfirm) {
          return
        }
      }

      this.huayTabIndex = ind
      this.$refs.number?.focus()
      this.clearTmp()

      setTimeout(
        function () {
          this.$refs.number?.focus()
        }.bind(this),
        400
      )
    },
    clearTmp() {
      this.number = ""
      this.buyAmount1 = null
      this.buyAmount2 = null
      this.numberTmps = []
      this.resetWinNumer()
    },
    onOptionDoubleClick() {
      Array.from(Array(10).keys()).forEach((ele) => {
        this.numberTmps.push(ele.toString().repeat(this.maxLength))
      })
    },
    onHuayPayChange() {
      this.recheckNumberGroups()
    },
    onNumberPaste(evt) {
      var text = evt.clipboardData.getData("text")

      var newNums = text
        .split(/[\s:*=|/.,//-]+/)
        .filter((str) => str.length == this.maxLength && /^\d+$/.test(str))

      newNums.forEach((num) => {
        var arr = []
        switch (this.huayTabIndex) {
          case "nineteen": {
            arr = this.generateNineteenDoor(num)
            break
          }
          case "six": {
            arr = this.generateUniqueReverse(num)
            break
          }
          default: {
            arr = [num]
          }
        }
        this.numberTmps = this.numberTmps.concat(arr)
      })
    },
    removeNumberTmpByIndex(index) {
      this.numberTmps.splice(index, 1)
    },
    onNumberChange() {
      if (this.number.length == this.maxLength) {
        if (["two", "three", "run"].indexOf(this.huayTabIndex) != -1) {
          this.numberTmps.push(this.number)
        } else if (this.huayTabIndex == "six") {
          var sixNumbers = this.generateUniqueReverse(this.number)
          this.numberTmps = this.numberTmps.concat(sixNumbers)
        } else if (this.huayTabIndex == "nineteen") {
          var nineteenNumbers = this.generateNineteenDoor(this.number)
          this.numberTmps = this.numberTmps.concat(nineteenNumbers)
        }

        this.number = ""
      }
    },
    reverseNumberTmps() {
      this.numberTmps.forEach((num) => {
        var reverseNums = this.generateUniqueReverse(num)
        reverseNums.splice(reverseNums.indexOf(num), 1)
        this.numberTmps = this.numberTmps.concat(reverseNums)
      })
    },
    addNumberToGroup() {
      if (this.numberTmps.length == 0) {
        this.$refs.number.focus()
        return
      }
      if (!this.buyAmount1 && !this.buyAmount2) {
        this.$refs.buyAmount1.focus()
        this.swal.swal("กรุณาใส่ราคา", "")
        return
      }

      this.$refs.number?.focus()
      var name = ""
      var type = ""
      var buyAmount1 = this.buyAmount1
      var buyAmount2 = this.buyAmount2
      var cateCode1 = ""
      var cateCode2 = ""
      switch (this.huayTabIndex) {
        case "two":
        case "nineteen": {
          cateCode1 = "BACK_2_TOP"
          cateCode2 = "BACK_2_BOTTOM"
          name = "2 ตัว"
          type = "two"
          break
        }
        case "three":
        case "six": {
          cateCode1 = "BACK_3_TOP"
          cateCode2 = "BACK_3_TOP_CROSS"
          name = "3 ตัว"
          type = "three"
          break
        }
        case "run": {
          cateCode1 = "RUN_BACK_TOP"
          cateCode2 = "RUN_BACK_BOTTOM"
          name = "วิ่ง"
          type = "run"
          break
        }
      }

      if (this.huayTabIndex == "win" && this.winNumber.winNumber == "win_2") {
        cateCode1 = "BACK_2_TOP"
        cateCode2 = "BACK_2_BOTTOM"
        name = "2 ตัว"
        type = "two"
      } else if (
        this.huayTabIndex == "win" &&
        this.winNumber.winNumber == "win_3"
      ) {
        cateCode1 = "BACK_3_TOP"
        cateCode2 = "BACK_3_TOP_CROSS"
        name = "3 ตัว"
        type = "three"
      }

      if (buyAmount1) {
        var cate1 = this.findCategoryByCode(cateCode1)
        if (cate1) {
          if (cate1.userHuayCategoryBuyMin > buyAmount1) {
            buyAmount1 = cate1.userHuayCategoryBuyMin
          }
          if (cate1.userHuayCategoryBuyMaxPerBill < buyAmount1) {
            buyAmount1 = cate1.userHuayCategoryBuyMaxPerBill
          }
        } else {
          buyAmount1 = 0
        }
      }
      if (buyAmount2) {
        var cate2 = this.findCategoryByCode(cateCode2)
        if (cate2) {
          if (cate2.userHuayCategoryBuyMin > buyAmount2) {
            buyAmount2 = cate2.userHuayCategoryBuyMin
          }
          if (cate2.userHuayCategoryBuyMaxPerBill < buyAmount2) {
            buyAmount2 = cate2.userHuayCategoryBuyMaxPerBill
          }
        } else {
          buyAmount2 = 0
        }
      }

      this.numberGroupList.push({
        name: name,
        type: type,
        cateCode1: cateCode1,
        cateCode2: cateCode2,
        buyAmount1: buyAmount1,
        buyAmount2: buyAmount2,
        numberList: this.numberTmps.slice()
      })

      this.clearTmp()
    },
    recheckNumberGroups() {
      this.numberGroupList = this.numberGroupList.map((group) => {
        if (group.buyAmount1) {
          var cate1 = this.findCategoryByCode(group.cateCode1)
          if (cate1) {
            if (cate1.userHuayCategoryBuyMin > group.buyAmount1) {
              group.buyAmount1 = cate1.userHuayCategoryBuyMin
            }
            if (cate1.userHuayCategoryBuyMaxPerBill < group.buyAmount1) {
              group.buyAmount1 = cate1.userHuayCategoryBuyMaxPerBill
            }
          } else {
            group.buyAmount1 = 0
          }
        }
        if (group.buyAmount2) {
          var cate2 = this.findCategoryByCode(group.cateCode2)
          if (cate2) {
            if (cate2.userHuayCategoryBuyMin > group.buyAmount2) {
              group.buyAmount2 = cate2.userHuayCategoryBuyMin
            }
            if (cate2.userHuayCategoryBuyMaxPerBill < group.buyAmount2) {
              group.buyAmount2 = cate2.userHuayCategoryBuyMaxPerBill
            }
          } else {
            group.buyAmount2 = 0
          }
        }
        return group
      })
    },
    async saveNumbers() {
      if (this.closeDiffTime <= 0) {
        return
      }
      this.numberList = []
      if (this.numberGroupList.length == 0) {
        return
      }

      await this.getLottoRoundDetail()

      this.numberGroupList.forEach((group) => {
        var cate1 = null
        var cate2 = null
        if (group.buyAmount1) {
          // let cateCode = group.type == 'three' ? 'BACK_3_TOP' : group.type == 'two' ? 'BACK_2_TOP' : 'RUN_BACK_TOP';
          cate1 = this.findCategoryByCode(group.cateCode1)
        }
        if (group.buyAmount2) {
          // let cateCode = group.type == 'three' ? 'BACK_3_TOP_CROSS' : group.type == 'two' ? 'BACK_2_BOTTOM' : 'RUN_BACK_BOTTOM';
          cate2 = this.findCategoryByCode(group.cateCode2)
        }

        group.numberList.forEach((num) => {
          const cats = [cate1, cate2]
          cats.forEach((cate, i) => {
            if (cate) {
              var crossNums = [num]
              if (cate.huayCategoryIsCross) {
                crossNums = this.generateUniqueReverse(num)
              }

              var minCrossNum = this.getMin(crossNums)

              var payRate = cate.payrate
              var betLimit = this.betLimits.find(
                (ele) =>
                  ele.huayCategoryId == cate.huayCategoryId &&
                  ele.number == minCrossNum
              )
              if (
                betLimit
                // && parseFloat(betLimit.payRate) < parseFloat(payRate)
              ) {
                if (!betLimit.isPercent) {
                  if (parseFloat(betLimit.payRate) < parseFloat(payRate)) {
                    payRate = betLimit.payRate
                  }
                } else {
                  payRate = Math.floor(
                    (parseFloat(payRate) * parseFloat(betLimit.payRate)) / 100
                  ).toString()
                }
              }
              var maxReceiveAmount =
                this.betReceiveCategoryMap[cate.huayCategoryId] || 0
              var receiveNumAmount =
                this.betReceiveNumberMap[
                  `${cate.huayCategoryId}_${minCrossNum}`
                ]
              if (
                receiveNumAmount != undefined &&
                receiveNumAmount < maxReceiveAmount
              ) {
                maxReceiveAmount = receiveNumAmount
              }
              // var oldAllTotalBuyAmount =
              //   this.allTotalBuyAmountMap[`${cate.huayCategoryId}_${num}`] || 0
              var oldAllTotalBuyAmount = 0
              crossNums.forEach((cn) => {
                oldAllTotalBuyAmount +=
                  this.allTotalBuyAmountMap[`${cate.huayCategoryId}_${cn}`] || 0
              })
              var oldTotalBuyAmount =
                this.totalBuyAmountMap[`${cate.huayCategoryId}_${num}`] || 0

              var sumBuyAmount =
                this.numberList
                  .filter(
                    (ele) =>
                      ele.huayCategoryId == cate.huayCategoryId &&
                      crossNums.includes(ele.number)
                    // ele.number == num
                  )
                  .reduce((a, b) => a + b["buyAmount"], 0) || 0

              var buyAmount = group[`buyAmount${i + 1}`]

              if (
                maxReceiveAmount <
                oldAllTotalBuyAmount + sumBuyAmount + buyAmount
              ) {
                if (maxReceiveAmount < oldAllTotalBuyAmount + sumBuyAmount) {
                  buyAmount = 0
                } else {
                  buyAmount =
                    maxReceiveAmount - (oldAllTotalBuyAmount + sumBuyAmount)
                }
              }

              if (buyAmount > 0) {
                if (
                  cate.userHuayCategoryBuyMaxPerNumber <
                  oldTotalBuyAmount + sumBuyAmount + buyAmount
                ) {
                  if (
                    cate.userHuayCategoryBuyMaxPerNumber <
                    oldTotalBuyAmount + sumBuyAmount
                  ) {
                    buyAmount = 0
                  } else {
                    buyAmount =
                      cate.userHuayCategoryBuyMaxPerNumber -
                      (oldTotalBuyAmount + sumBuyAmount)
                  }
                }
              }

              if (buyAmount > 0 && buyAmount < cate.userHuayCategoryBuyMin) {
                buyAmount = 0
              }

              this.numberList.push({
                huayCategoryId: cate.huayCategoryId,
                huayCategoryName: cate.huayCategoryName,
                huayCategoryCode: cate.huayCategoryCode,
                number: num,
                buyAmount: buyAmount,
                expectBuyAmount: group[`buyAmount${i + 1}`],
                payRate: payRate,
                expectPayRate: cate.payrate,
                commission: (
                  (parseFloat(cate.commission) * buyAmount) /
                  100.0
                ).toString()
              })
            }
          })
        })
      })
      this.mainTabIndex = 1
    },
    clearNumberTmps() {
      this.numberTmps = []
      this.resetWinNumer()
    },
    clearTable() {
      this.numberTmps = []
      this.numberGroupList = []
      this.note = ""
      this.number = ""
      this.buyAmount1 = ""
      this.buyAmount2 = ""
      this.resetWinNumer()
    },
    removeNumberGroupList(index) {
      this.numberGroupList.splice(index, 1)
    },
    findCategoryByCode(categoryCode) {
      return this.categories.find((ele) => ele.code == categoryCode)
    },
    cancelConfirm() {
      this.mainTabIndex = 0
    },
    async confirm() {
      if (this.closeDiffTime <= 0) {
        return
      }

      var billItem = this.numberList.filter((ele) => ele.buyAmount != 0)
      if (billItem.length == 0) {
        return
      }

      if (this.numberList.some((ele) => ele.buyAmount == 0)) {
        let confirm = await this.swal.confirm(
          "มีเลขปิดรับ",
          "ต้องการเพิ่มบิลหรือไม่"
        )
        if (!confirm) {
          return
        }
      } else if (
        this.numberList.some((ele) => ele.expectBuyAmount != ele.buyAmount)
      ) {
        let confirm = await this.swal.confirm(
          "มีเลขถูกเปลี่ยนยอด",
          "ต้องการเพิ่มบิลหรือไม่"
        )
        if (!confirm) {
          return
        }
      }

      var data = {
        huayRoundId: this.lottoRound.id,
        items: billItem,
        note: this.note,
        huayPayId: this.lottoPayId
      }

      this.swal.processing()
      const res = await lottoService.addBill(data)
      if (res.error) {
        var callback = null
        if (res.error.code === -1001) {
          callback = async function () {
            await this.getLottoRoundDetail()
            this.cancelConfirm()
            this.recheckNumberGroups()
          }.bind(this)
        }
        this.swal.swalError(res.error.message, callback)
      } else {
        this.$store.dispatch("forceReloadView")
        // this.swal.swalSuccess(
        //   "ส่งโพยแล้ว",
        //   function() {
        //   }.bind(this)
        // )
      }
    },
    async cancelBill() {
      var bill = this.currentLatestBill
      var confirm = await this.swal.confirm("คืนโพย", "ท่านต้องการคืนโพย?")
      if (!confirm) {
        return
      }
      // if (!bill.cancelable) {
      //   var msgBeforeClose = "";
      //   if (bill.cancelMinuteBeforeClose) {
      //     msgBeforeClose = ` ${bill.cancelMinuteBeforeClose} นาที `;
      //   }
      //   var message = `ท่านสามารถคืนโพยหวยได้ก่อนงวดหวยปิด${msgBeforeClose}เท่านั้น`
      //   this.$swal({
      //     text: message,
      //     icon: "warning",
      //   });
      //   return;
      // }

      this.swal.processing()
      const res = await lottoService.cancelBill(bill.id)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          "คืนโพยสำเร็จ",
          function () {
            this.$store.dispatch("forceReloadView")
          }.bind(this)
        )
      }
    },
    generateUniqueReverse(str) {
      var tree = function (leafs) {
        var branches = []
        if (leafs.length == 1) return [leafs]
        for (var k in leafs) {
          var leaf = leafs[k]
          tree(leafs.join("").replace(leaf, "").split(""))
            .concat("")
            .map(function (subtree) {
              branches.push([leaf].concat(subtree))
            })
        }
        return branches
      }
      var arr = tree(str.split(""))
        .map(function (item) {
          return item.join("")
        })
        .filter((item) => item.length == str.length)
      return Array.from(new Set(arr))
    },
    generateNineteenDoor(number) {
      var numberList = []
      "0123456789".split("").forEach((num) => {
        numberList.push(num + number)
        numberList.push(number + num)
      })

      return Array.from(new Set(numberList))
    },
    generateRoodFront(number) {
      return "0123456789".split("").map((last) => number + last)
    },
    generateRoodBack(number) {
      return "0123456789".split("").map((first) => first + number)
    },
    getMin(arr) {
      if (!arr) {
        return null
      }
      var minV = arr[0]
      for (let a of arr) {
        if (a < minV) minV = a
      }
      return minV
    },
    keypressHandler(evt) {
      if (this.huayTabIndex != "win") {
        return
      }

      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode

      switch (String.fromCharCode(charCode)) {
        case " ":
          this.calWinNumber()
          break
        case "0":
          this.winNumber.winPadNumber0 = !this.winNumber.winPadNumber0
          break
        case "1":
          this.winNumber.winPadNumber1 = !this.winNumber.winPadNumber1
          break
        case "2":
          this.winNumber.winPadNumber2 = !this.winNumber.winPadNumber2
          break
        case "3":
          this.winNumber.winPadNumber3 = !this.winNumber.winPadNumber3
          break
        case "4":
          this.winNumber.winPadNumber4 = !this.winNumber.winPadNumber4
          break
        case "5":
          this.winNumber.winPadNumber5 = !this.winNumber.winPadNumber5
          break
        case "6":
          this.winNumber.winPadNumber6 = !this.winNumber.winPadNumber6
          break
        case "7":
          this.winNumber.winPadNumber7 = !this.winNumber.winPadNumber7
          break
        case "8":
          this.winNumber.winPadNumber8 = !this.winNumber.winPadNumber8
          break
        case "9":
          this.winNumber.winPadNumber9 = !this.winNumber.winPadNumber9
          break
      }
    }
  },
  updated() {
    // setTimeout(function () {
    //   this.$refs.number?.focus();
    // }.bind(this), 1000)
  },
  async mounted() {
    window.addEventListener("keypress", this.keypressHandler)

    this.resetWinNumer()

    setTimeout(
      function () {
        this.$refs.number?.focus()
      }.bind(this),
      1000
    )

    this.$store.dispatch("lotto/clearLottoPay", null, { root: true })
    this.lottoRoundId = this.$router.currentRoute.params.roundId
    this.getLottoRoundDetail("READ")
  },
  destroyed() {
    this.$store.dispatch("lotto/clearLottoPay", null, { root: true })
  }
}
