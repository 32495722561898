const formatMoney = function(amount) {
  if (!amount) {
    return "0.00";
  }
  return parseFloat(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2
  });
};

const formatNumberWithComma = function(amount) {
  return parseFloat(amount).toLocaleString("en-US");
};

const getClassAmount = function(a) {
  if (!a || parseFloat(a) == parseFloat(0)) {
    return "";
  }
  if (parseFloat(a) > parseFloat(0)) {
    return "positive";
  }
  return "negative";
};

export default {
  formatMoney,
  getClassAmount,
  formatNumberWithComma
};
