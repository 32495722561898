import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import lotto from "./lotto";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mainViewIndex: 0,
    reloadTimeout: true
  },
  mutations: {
    forceReloadViewDelay(state) {
      if (state.reloadTimeout) {
        state.reloadTimeout = false;
        state.mainViewIndex++;
        setTimeout(() => {
          state.reloadTimeout = true;
        }, 5000);
      }
    },
    forceReloadView(state) {
      state.mainViewIndex++;
    }
  },
  actions: {
    forceReloadViewDelay({ commit }) {
      commit("forceReloadViewDelay");
    },
    forceReloadView({ commit }) {
      commit("forceReloadView");
    }
  },
  modules: {
    auth,
    lotto,
  }
});
