var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"show":!_vm.isLoading,"dismissible":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.news)}})]),_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}]},_vm._l((_vm.lottoGroups),function(item){return _c('div',{key:item.id,staticClass:"card border-primary mb-3"},[_c('div',{staticClass:"card-header border-primary bg-primary text-white py-1"},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"card-body bg-light p-1"},[_c('div',{staticClass:"row m-0"},_vm._l((_vm.filteredLottoTypesByGroup(item.id)),function(lottoType,index){return _c('div',{key:index,staticClass:"col-6 col-md-2 p-1"},[(!lottoType.lottoRound)?_c('div',{staticClass:"boxhuay border border-gray rounded cursor-pointer",class:{ disabled: !lottoType.multiRoundPerDay },on:{"click":function($event){return _vm.clickClosedRound(lottoType)}}},[_c('div',{staticClass:"d-flex flex-row bg-warning"},[_c('div',{staticClass:"text-center",staticStyle:{"width":"52px"}},[(!lottoType.icon)?[(lottoType.image)?_c('div',{staticClass:"w-100 h-100 flag-icon",style:(("background-image: url('" + (lottoType.image) + "')"))}):_vm._e(),(!lottoType.image)?_c('i',{staticClass:"fullflag fab fa-slack-hash",staticStyle:{"font-size":"2rem"}}):_vm._e()]:_vm._e(),_c('flag',{staticClass:"w-100 h-100",attrs:{"iso":lottoType.icon}})],2),_c('div',{staticClass:"w-100 d-flex flex-column text-right"},[_c('div',{staticClass:"pr-1 text-black",class:{
                      'text-play-head text-white': lottoType.multiRoundPerDay,
                    },staticStyle:{"font-size":"14px"}},[_c('span',[_vm._v(_vm._s(lottoType.name)+" ")])]),_c('div',{staticClass:"pr-1",class:{
                      'bg-gray': lottoType.multiRoundPerDay,
                      'bg-secondary text-white': !lottoType.multiRoundPerDay,
                    },staticStyle:{"font-size":"12px"}},[(!lottoType.multiRoundPerDay)?_c('i',{staticClass:"far fa-calendar-times mr-1"}):_vm._e(),_vm._v(" "+_vm._s(lottoType.multiRoundPerDay ? "เปิดแทง 24 ชม." : ("ปิดรับ " + (lottoType.closeTime.substring(0, 5))))+" ")])])]),_c('div',{staticClass:"p-1 text-right h4 text-nowrap mb-0 overflow-hidden",class:{
                  'bg-white text-primary': lottoType.multiRoundPerDay,
                  'bg-dark text-secondary': !lottoType.multiRoundPerDay,
                }},[(!lottoType.multiRoundPerDay)?_c('i',{staticClass:"far fa-calendar-times mr-1"}):_vm._e(),_vm._v(_vm._s(lottoType.multiRoundPerDay ? "24 ชม." : "ยังไม่รับแทง")+" ")])]):_vm._e(),(lottoType.lottoRound)?_c('div',{staticClass:"boxhuay border border-gray rounded cursor-pointer",class:{ disabled: !lottoType.lottoRound.isOpen },on:{"click":function($event){lottoType.lottoRound.isOpen &&
                  _vm.$router.push(("/bet/" + (lottoType.lottoRound.id)))}}},[_c('div',{staticClass:"d-flex flex-row bg-warning"},[_c('div',{staticClass:"text-center",staticStyle:{"width":"52px"}},[(!lottoType.icon)?[(lottoType.image)?_c('div',{staticClass:"w-100 h-100 flag-icon",style:(("background-image: url('" + (lottoType.image) + "')"))}):_vm._e(),(!lottoType.image)?_c('i',{staticClass:"fullflag fab fa-slack-hash",staticStyle:{"font-size":"2rem"}}):_vm._e()]:_vm._e(),_c('flag',{staticClass:"w-100 h-100",attrs:{"iso":lottoType.icon}})],2),_c('div',{staticClass:"w-100 d-flex flex-column text-right"},[_c('div',{staticClass:"pr-1",class:{
                      'text-play-head text-white':
                        lottoType.lottoRound.isOpen,
                      'text-black': !lottoType.lottoRound.isOpen,
                    },staticStyle:{"font-size":"14px"}},[_c('span',[_vm._v(_vm._s(lottoType.lottoRound.typeName)+" ")])]),_c('div',{staticClass:"pr-1",class:{
                      'bg-gray text-black': lottoType.lottoRound.isOpen,
                      'bg-secondary text-white': !lottoType.lottoRound.isOpen,
                    },staticStyle:{"font-size":"12px"}},[_vm._v(" ปิดรับ "+_vm._s(_vm.$date(lottoType.lottoRound.closeTime).format("HH:mm"))+" ")])])]),_c('div',{staticClass:"p-1 text-right text-secondary text-nowrap overflow-hidden",class:{
                  'bg-white': lottoType.lottoRound.isOpen,
                  'bg-dark': !lottoType.lottoRound.isOpen,
                }},[(!lottoType.lottoRound.isOpen)?_c('h4',{staticClass:"text-secondary mb-0"},[_c('i',{staticClass:"far fa-calendar-times mr-1"}),_vm._v("ยังไม่รับแทง ")]):_vm._e(),(lottoType.lottoRound.isOpen)?_c('h4',{staticClass:"text-primary mb-0"},[(
                      _vm.closeDiffTime(lottoType.lottoRound.closeTime) &&
                      _vm.closeDiffTime(lottoType.lottoRound.closeTime) > 0
                    )?_c('countdown',{attrs:{"time":_vm.closeDiffTime(lottoType.lottoRound.closeTime)},on:{"end":_vm.onCountdownEnd},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.days == 0 ? "" : props.days + " วัน")+" "+_vm._s(props.hours.toString().padStart(2, "0"))+":"+_vm._s(props.minutes.toString().padStart(2, "0"))+":"+_vm._s(props.seconds.toString().padStart(2, "0"))+" ")]}}],null,true)}):_vm._e(),(
                      !_vm.closeDiffTime(lottoType.lottoRound.closeTime) ||
                      _vm.closeDiffTime(lottoType.lottoRound.closeTime) <= 0
                    )?_c('span',[_vm._v("00:00:00")]):_vm._e()],1):_vm._e()])]):_vm._e()])}),0)])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }