<template>
  <div>
    <b-alert :show="!isLoading" dismissible>
      <div v-html="news"></div>
    </b-alert>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div
        class="card border-primary mb-3"
        v-for="item in lottoGroups"
        :key="item.id"
      >
        <div class="card-header border-primary bg-primary text-white py-1">
          <span>{{ item.name }}</span>
        </div>
        <div class="card-body bg-light p-1">
          <div class="row m-0">
            <div
              class="col-6 col-md-2 p-1"
              v-for="(lottoType, index) in filteredLottoTypesByGroup(item.id)"
              :key="index"
            >
              <div
                v-if="!lottoType.lottoRound"
                class="boxhuay border border-gray rounded cursor-pointer"
                :class="{ disabled: !lottoType.multiRoundPerDay }"
                @click="clickClosedRound(lottoType)"
              >
                <div class="d-flex flex-row bg-warning">
                  <div class="text-center" style="width: 52px">
                    <template v-if="!lottoType.icon">
                      <div
                        v-if="lottoType.image"
                        class="w-100 h-100 flag-icon"
                        :style="`background-image: url('${lottoType.image}')`"
                      />
                      <i
                        v-if="!lottoType.image"
                        class="fullflag fab fa-slack-hash"
                        style="font-size: 2rem"
                      ></i>
                    </template>
                    <flag :iso="lottoType.icon" class="w-100 h-100" />
                  </div>
                  <div class="w-100 d-flex flex-column text-right">
                    <div
                      class="pr-1 text-black"
                      style="font-size: 14px"
                      :class="{
                        'text-play-head text-white': lottoType.multiRoundPerDay,
                      }"
                    >
                      <span>{{ lottoType.name }} </span>
                    </div>
                    <div
                      class="pr-1"
                      :class="{
                        'bg-gray': lottoType.multiRoundPerDay,
                        'bg-secondary text-white': !lottoType.multiRoundPerDay,
                      }"
                      style="font-size: 12px"
                    >
                      <i
                        class="far fa-calendar-times mr-1"
                        v-if="!lottoType.multiRoundPerDay"
                      ></i>
                      {{
                        lottoType.multiRoundPerDay
                          ? "เปิดแทง 24 ชม."
                          : `ปิดรับ ${lottoType.closeTime.substring(0, 5)}`
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="p-1 text-right h4 text-nowrap mb-0 overflow-hidden"
                  :class="{
                    'bg-white text-primary': lottoType.multiRoundPerDay,
                    'bg-dark text-secondary': !lottoType.multiRoundPerDay,
                  }"
                >
                  <i
                    class="far fa-calendar-times mr-1"
                    v-if="!lottoType.multiRoundPerDay"
                  ></i
                  >{{ lottoType.multiRoundPerDay ? "24 ชม." : "ยังไม่รับแทง" }}
                </div>
              </div>
              <div
                v-if="lottoType.lottoRound"
                class="boxhuay border border-gray rounded cursor-pointer"
                :class="{ disabled: !lottoType.lottoRound.isOpen }"
                @click="
                  lottoType.lottoRound.isOpen &&
                    $router.push(`/bet/${lottoType.lottoRound.id}`)
                "
              >
                <div class="d-flex flex-row bg-warning">
                  <div class="text-center" style="width: 52px">
                    <template v-if="!lottoType.icon">
                      <div
                        v-if="lottoType.image"
                        class="w-100 h-100 flag-icon"
                        :style="`background-image: url('${lottoType.image}')`"
                      />
                      <i
                        v-if="!lottoType.image"
                        class="fullflag fab fa-slack-hash"
                        style="font-size: 2rem"
                      ></i>
                    </template>
                    <flag :iso="lottoType.icon" class="w-100 h-100" />
                  </div>
                  <div class="w-100 d-flex flex-column text-right">
                    <div
                      class="pr-1"
                      style="font-size: 14px"
                      :class="{
                        'text-play-head text-white':
                          lottoType.lottoRound.isOpen,
                        'text-black': !lottoType.lottoRound.isOpen,
                      }"
                    >
                      <span>{{ lottoType.lottoRound.typeName }} </span>
                    </div>
                    <div
                      class="pr-1"
                      :class="{
                        'bg-gray text-black': lottoType.lottoRound.isOpen,
                        'bg-secondary text-white': !lottoType.lottoRound.isOpen,
                      }"
                      style="font-size: 12px"
                    >
                      ปิดรับ
                      {{
                        $date(lottoType.lottoRound.closeTime).format("HH:mm")
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="p-1 text-right text-secondary text-nowrap overflow-hidden"
                  :class="{
                    'bg-white': lottoType.lottoRound.isOpen,
                    'bg-dark': !lottoType.lottoRound.isOpen,
                  }"
                >
                  <h4
                    class="text-secondary mb-0"
                    v-if="!lottoType.lottoRound.isOpen"
                  >
                    <i class="far fa-calendar-times mr-1"></i>ยังไม่รับแทง
                  </h4>
                  <h4
                    class="text-primary mb-0"
                    v-if="lottoType.lottoRound.isOpen"
                  >
                    <countdown
                      v-if="
                        closeDiffTime(lottoType.lottoRound.closeTime) &&
                        closeDiffTime(lottoType.lottoRound.closeTime) > 0
                      "
                      @end="onCountdownEnd"
                      :time="closeDiffTime(lottoType.lottoRound.closeTime)"
                    >
                      <template slot-scope="props">
                        {{ props.days == 0 ? "" : props.days + " วัน" }}
                        {{ props.hours.toString().padStart(2, "0") }}:{{
                          props.minutes.toString().padStart(2, "0")
                        }}:{{ props.seconds.toString().padStart(2, "0") }}
                      </template>
                    </countdown>
                    <span
                      v-if="
                        !closeDiffTime(lottoType.lottoRound.closeTime) ||
                        closeDiffTime(lottoType.lottoRound.closeTime) <= 0
                      "
                      >00:00:00</span
                    >
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.boxhuay.disabled {
  filter: grayscale(1) opacity(0.7);
}

.flag-icon {
  display: flex;
  background-size: cover !important;
}

.text-play-head {
  text-shadow: rgb(0 0 0) 2px 0px 0px, rgb(0 0 0) 1.75517px 0.958851px 0px,
    rgb(0 0 0) 1.0806px 1.68294px 0px, rgb(0 0 0) 0.141474px 1.99499px 0px,
    rgb(0 0 0) -0.832294px 1.81859px 0px, rgb(0 0 0) -1.60229px 1.19694px 0px,
    rgb(0 0 0) -1.97998px 0.28224px 0px, rgb(0 0 0) -1.87291px -0.701566px 0px,
    rgb(0 0 0) -1.30729px -1.5136px 0px, rgb(0 0 0) -0.421592px -1.95506px 0px,
    rgb(0 0 0) 0.567324px -1.91785px 0px, rgb(0 0 0) 1.41734px -1.41108px 0px,
    rgb(0 0 0) 1.92034px -0.558831px 0px;
}

.fullflag {
  font-size: 20px;
  letter-spacing: normal;
  line-height: 39px;
  color: black;
  text-shadow: rgb(255 255 255 / 50%) 1px 1px 0;
}
</style>

<script>
import LottoProvider from "@/resources/lotto_provider"

const lottoService = new LottoProvider()

export default {
  name: "LottoList",
  data() {
    return {
      isLoading: true,
      list: [],
      lottoGroups: [],
      lottoTypes: [],
      lottoRoundClosed: [],
      news: "",
    }
  },
  methods: {
    clickClosedRound(lottoType) {
      if (lottoType.multiRoundPerDay) {
        this.$router.push(`/lotto/list/${lottoType.id}`)
      } else {
        let item = this.filterRoundClosed(lottoType.id)
        if (item) {
          this.$router.push(`/lotto/${item.id}`)
        }
      }
    },
    filterRoundClosed(typeId) {
      return this.lottoRoundClosed.find((item) => item.huayTypeId == typeId)
    },
    closeDiffTime(closeTime) {
      return (this.$date(closeTime).unix() - this.$date().unix()) * 1000
    },
    filteredLottoTypesByGroup(groupId) {
      return this.lottoTypes.filter((ele) => ele.huayGroupId == groupId)
    },
    isOpen(item) {
      return (
        this.$date().isBetween(
          this.$date(item.openTime),
          this.$date(item.closeTime)
        ) && item.status == "OPEN"
      )
    },
    onCountdownEnd() {
      this.$store.dispatch("forceReloadView")
    },
  },
  async mounted() {
    this.isLoading = true

    try {
      const promNews = this.axios({
        method: "get",
        url: "/news",
      })
      const promLottoPlayList = lottoService.getLottoPlayList()

      const [resNews, resLottoPlayList] = await Promise.all([
        promNews,
        promLottoPlayList,
      ])

      this.news = resNews.data.data

      if (resLottoPlayList.error) {
        this.swal.swalError(resLottoPlayList.error.message)
      } else {
        resLottoPlayList.result.huayGroups.forEach(
          (item) => (item.active = false)
        )
        resLottoPlayList.result.huayGroups.sort(function (a, b) {
          return a.showOrder > b.showOrder ? 1 : -1
        })
        this.lottoGroups = resLottoPlayList.result.huayGroups
        resLottoPlayList.result.huayTypes.sort(function (a, b) {
          return a.showOrder > b.showOrder ? 1 : -1
        })
        resLottoPlayList.result.huayTypes.forEach((item) => {
          if (item.image && !item.image.includes("https")) {
            item.image = require(`@/assets/images/lotto-type/${item.image}`)
          }
          let lottoRound =
            resLottoPlayList.result.huayRounds &&
            resLottoPlayList.result.huayRounds.find(
              (item2) => item.id == item2.huayTypeId
            )
          if (lottoRound) {
            lottoRound.isOpen = this.isOpen(lottoRound)
            item.lottoRound = lottoRound
          }
        })
        this.lottoRoundClosed = resLottoPlayList.result.huayRoundClosed
        this.lottoTypes = resLottoPlayList.result.huayTypes
      }

      this.isLoading = false
    } catch (e) {
      console.log(e)
      this.swal.swalError()
    }
  },
}
</script>