import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Logout.vue")
  },
  {
    path: "*",
    name: "Root",
    component: () => import("@/layouts/Main.vue"),
    redirect: "/bet",
    children: [
      {
        path: "/bet",
        name: "LottoList",
        component: () => import("@/views/LottoList.vue")
      },
      {
        path: "/bet/:roundId",
        name: "LottoPlay",
        component: () => import("@/views/LottoPlay/template")
      },
      {
        path: "/bill",
        name: "Bill",
        component: () => import("@/views/Bill.vue")
      },
      {
        path: "/report/by-date",
        name: "ReportByDate",
        component: () => import("@/views/ReportByDate.vue")
      },
      {
        path: "/report/by-round/:roundId",
        name: "ReportByRound",
        component: () => import("@/views/Bill.vue")
      },
      {
        path: "/report/game-result",
        name: "ReportGameResult",
        component: () => import("@/views/ReportGameResult.vue")
      },
      {
        path: "/report/game-result/about",
        name: "ReportGameResultAbout",
        component: () => import("@/views/ReportGameResultAbout.vue")
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/views/Profile.vue")
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login"]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem("user")

  if (authRequired && !loggedIn) {
    return next("/login")
  }

  next()
})

export default router
