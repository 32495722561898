<template>
  <div id="app">
    <router-view :key="$store.state.mainViewIndex" />
  </div>
</template>

<style lang="scss">
@import "assets/style/style";
</style>

<script>
import update from "./mixins/update";

export default {
  mixins: [update],
};
</script>