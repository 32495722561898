<template>
  <div>
    <div class="mb-2">
      <h4>
        <i class="fa fa-angle-right text-primary mr-1"></i>
        <span class="text-gold">
          วันที่ {{ $date(date).format("DD-MM-YYYY") }}
        </span>
      </h4>
      <div class="d-flex">
        <button class="btn btn-primary mr-2" @click="initData(-1)">
          <i class="fa fa-angle-left"></i>
        </button>
        <b-form-datepicker
          class="mr-2"
          style="width: 150px"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="th"
          v-model="date"
          @context="initData(0)"
        />
        <button class="btn btn-primary" @click="initData(1)">
          <i class="fa fa-angle-right"></i>
        </button>
      </div>
    </div>
    <loading v-show="isLoading" />
    <div class="table-responsive" v-show="!isLoading">
      <table class="table table-hover table-bordered table-dark text-center">
        <thead>
          <tr>
            <th rowspan="2" class="align-middle">ชนิดหวย</th>
            <th rowspan="2" class="align-middle">ชื่องวด</th>
            <th v-for="item in categorys" :key="item.id">{{ item.name }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in huayGroups">
            <tr :key="index * -1">
              <td colspan="9" class="bg-primary">{{ item.name }}</td>
            </tr>
            <tr :key="huayType.id" v-for="huayType in filterHuayTypes(item.id)">
              <td>{{ huayType.name }}</td>
              <td>{{ huayType.huayRound.name }}</td>
              <td
                class="text-info"
                v-for="(result, index) in huayType.results"
                :key="'result' + index"
              >
                {{ getNumber(result.result) }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: this.$date().format("YYYY-MM-DD"),
      huayGroups: [],
      huayRounds: [],
      huayTypes: [],
      categorys: [],
      isLoading: true,
    }
  },
  methods: {
    getNumber(number) {
      return number ? number : "-"
    },
    filterHuayTypes(huayGroupId) {
      return this.huayTypes.filter((item) => item.huayGroupId == huayGroupId)
    },
    async initData(day) {
      this.isLoading = true
      this.date = this.$date(this.date).add(day, "days").format("YYYY-MM-DD")

      try {
        let res = await this.axios({
          method: "get",
          url: "report/huay-result/" + this.date,
        })

        let data = res.data.data

        this.categorys = data.categorys

        data.huayTypes.forEach((item) => {
          item.huayRound = data.huayRounds?.find(
            (item2) => item2.huayTypeId == item.id
          )

          if (item.huayRound) {
            let results = data.huayRoundResults?.filter(
              (item2) => item2.huayRoundId == item.huayRound.id
            )

            if (!results || results.length == 0) {
              let tmp = []
              this.categorys.forEach(() => {
                tmp.push({})
              })

              item.results = tmp

              return
            }

            item.results = results
          }
        })

        this.huayTypes = data.huayTypes.filter((item) => item.results)
        this.huayGroups = data.groups.filter((item) =>
          this.huayTypes.find((item2) => item2.huayGroupId == item.id)
        )

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
}
</script>