import Swal from "sweetalert2"

export default {
  data() {
    return {
      refreshing: false,
      registration: null
    }
  },

  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true
    })

    navigator.serviceWorker.addEventListener("controllerchange", async () => {
      if (this.refreshing) return
      this.refreshing = true

      await Swal({
        title: "ทางเว็บมีการอัพเดตระบบ กดตกลงเพื่ออัพเดต",
        icon: "info",
        confirmButtonText: "ตกลง"
      })

      window.location.reload()
    })
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail

      this.refreshApp()
    },

    refreshApp() {
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" })
    }
  }
}
