<template>
  <div>
    <!-- <h6 class="page-header">ข้อมูลผู้ใช้</h6> -->
    <div class="card">
      <div class="card-header bg-light text-black">ข้อมูลผู้ใช้</div>
      <div class="card-body alert-primary">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group row">
              <div class="col-12 col-md-6">
                <div class="w-100">
                  <strong>ชื่อ - สกุล:</strong>
                </div>
                <div class="w-100">{{ user.name || "-" }}</div>
              </div>
              <div class="col-12 col-md-6">
                <div class="w-100">
                  <strong>เบอร์โทร:</strong>
                </div>
                <div class="w-100">
                  {{ user.phoneNo || "-" }}
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="w-100">
                  <strong>ระดับ:</strong>
                </div>
                <div class="w-100">
                  {{ user.levelTH }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <button
                class="btn btn-rounded rounded-circle btn-warning mr-1"
                @click="$bvModal.show('modal-change-password')"
              >
                <i class="fa fa-key"></i>
              </button>
              <span
                class="cursor-pointer"
                @click="$bvModal.show('modal-change-password')"
                >เปลี่ยนรหัสผ่าน</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-change-password"
      size="md"
      dialog-class="my-0"
      header-class="p-0"
      footer-class="p-0"
      hide-footer
      scrollable
    >
      <template slot="modal-header">
        <div class="w-100 p-3 text-center position-relative">
          <span>เปลียนรหัสผ่าน</span>

          <i
            class="fa fa-times position-absolute cursor-pointer"
            style="right: 16px; top: 50%; transform: translateY(-50%)"
            @click="$bvModal.hide('modal-change-password')"
          ></i>
        </div>
      </template>
      <template slot="modal-footer">
        <div class="w-100 text-center">
          <button class="btn btn-warning">เปลี่ยนรหัสผ่าน</button>
        </div>
      </template>
      <ValidationObserver
        ref="observer"
        tag="form"
        class="w-100"
        @submit.prevent="changePassword"
        novalidate
      >
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="form-group row">
              <label for="old-password" class="col-12 col-sm-4">
                รหัสผ่านเดิม:
              </label>
              <div class="col-12 col-sm-8">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="old-password"
                    type="password"
                    class="form-control mr-1"
                    v-model="form.oldPassword"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="new-password" class="col-12 col-sm-4">
                รหัสผ่านใหม่:
              </label>
              <div class="col-12 col-sm-8">
                <ValidationProvider
                  name="password"
                  vid="password"
                  rules="required|min:6"
                  v-slot="v"
                >
                  <input
                    id="new-password"
                    type="password"
                    class="form-control mr-1"
                    v-model="form.newPassword"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="new-password-confirm" class="col-12 col-sm-4">
                รหัสผ่านใหม่ อีกครั้ง:
              </label>
              <div class="col-12 col-sm-8">
                <ValidationProvider
                  rules="required|password_confirm:@password"
                  v-slot="v"
                >
                  <input
                    id="new-password-confirm"
                    type="password"
                    class="form-control mr-1"
                    v-model="form.newPasswordConfirm"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <div class="text-center">
              <button class="btn btn-warning" type="submit">
                เปลี่ยนรหัสผ่าน
              </button>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import UserProvider from "@/resources/user_provider";
const userService = new UserProvider();

export default {
  name: "Account",
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      },
    };
  },
  computed: {
    user: function () {
      return this.$store.state.auth.user.userData;
    },
  },
  methods: {
    async changePassword() {
      let observer = this.$refs.observer;
      let isValid = await observer.validate();
      if (!isValid) {
        this.focusError(observer);
        return;
      }
      this.swal.processing();
      const res = await userService.changePassword({
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword,
      });
      if (res.error) {
        this.swal.swalError(res.error.message);
      } else {
        this.swal.swalSuccess("เปลี่ยนรหัสผ่านสำเร็จ", function () {
            this.$store.dispatch("forceReloadView");
        }.bind(this));
      }
    },
  },
};
</script>