import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// plugin
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import dayjs from "dayjs";
import FlagIcon from "vue-flag-icon";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { CDataTable, CCollapse, CPagination, CCardBody } from "@coreui/vue";

// helpers
import util from "@/helpers/util";
import swal from "@/helpers/swal";
import * as input from "@/helpers/input";
import 'bootstrap'

// setup
import setup from "@/helpers/setup";

// components
import Loading from "@/components/Loading";

setup();

Vue.use(BootstrapVue, IconsPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(FlagIcon);

Vue.component(VueCountdown.name, VueCountdown);
Vue.component("loading", Loading);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("CDataTable", CDataTable);
Vue.component("CCollapse", CCollapse);
Vue.component("CPagination", CPagination);
Vue.component("CCardBody", CCardBody);

Vue.prototype.$date = dayjs;
Vue.prototype.util = util;
Vue.prototype.swal = swal;
Vue.prototype.input = input;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {},
}).$mount("#app");
