import HttpRequest from "./http_request"

class LottoProvider extends HttpRequest {
  async getLottoPlayList() {
    return this.get("huay/play/list")
  }

  async getLottoPlayDetail(roundId, resolver) {
    return this.get(`huay/play/${roundId}/detail`, { resolver })
  }

  async addBill(data) {
    return this.create("huay/bill/add", data)
  }

  async getBillDetail(id) {
    return this.get(`users/bill/${id}`)
  }

  async cancelBill(id) {
    return this.updateSome(`users/bill/cancelled/${id}`)
  }
}

export default LottoProvider
