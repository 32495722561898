const state = {
    lottoPays: [],
    currentLottoPayId: null,
    lottoCategoryPays: [],
}

const getters = {
    currentLottoCategoryPays: (state) => {
        return state.lottoCategoryPays.filter((ele) => ele.huayPayId == state.currentLottoPayId);
    }
}

const mutations = {
    setLottoPays: (state, lottoPays) => {
        state.lottoPays = lottoPays;
    },
    setCurrentLottoPayId: (state, currentLottoPayId) => {
        state.currentLottoPayId = currentLottoPayId;
    },
    setLottoCategoryPays: (state, categoryPays) => {
        state.lottoCategoryPays = categoryPays;
    },
}

const actions = {
    clearLottoPay({commit}) {
        commit("setLottoPays", []);
        commit("setCurrentLottoPayId", null);
        commit("setLottoCategoryPays", []);
    }
}

const modules = {}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules,
}