import { setupAxios } from "./axios";
import validate from "./validate";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import th from "dayjs/locale/th";

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale({
  ...th,
  weekStart: 1
});
dayjs.tz.setDefault("Asia/Bangkok");

setupAxios();

validate.setupValidate();

export default () => {};
