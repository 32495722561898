<template>
  <div>
    <div class="card border-primary bg-transparent mb-4" style="width: 700px">
      <h6 class="card-header border-primary bg-primary text-white p-2">
        ตัวเลือกการค้นหา
      </h6>
      <div class="card-body">
        <div class="d-flex mb-2">
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="fromDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <span class="mx-2 my-auto">ถึง</span>
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="toDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <button class="btn btn-primary ml-2" @click="loadData">ค้นหา</button>
        </div>

        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('TODAY')"
        >
          วันนี้
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('YESTERDAY')"
        >
          เมื่อวาน
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('WEEK')"
        >
          สัปดาห์นี้
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('LASTWEEK')"
        >
          สัปดาห์ที่แล้ว
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('MONTH')"
        >
          เดือนนี้
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('LASTMONTH')"
        >
          เดือนที่แล้ว
        </button>
      </div>
    </div>

    <CDataTable
      :items="items"
      :fields="fields"
      :items-per-page="20"
      :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }"
      column-filter
      :table-filter="{ label: 'ค้นหา', placeholder: 'ค้นหา' }"
      :items-per-page-select="{ label: 'จำนวนแถว' }"
      pagination
      dark
      border
      size="sm"
      addTableClasses="w-100 text-center text-nowrap"
    >
      <template #name="{ item }">
        <td>
          <router-link
            :to="{
              name: 'ReportByRound',
              params: { roundId: item.roundId },
            }"
            >{{ item.name }}</router-link
          >
        </td>
      </template>
      <template v-slot:footer>
        <tfoot>
          <tr>
            <td>รวม:</td>
            <td
              :class="{
                positive: sumItem.sumBuyAmount > 0,
                negative: sumItem.sumBuyAmount < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sumBuyAmount) }}
            </td>
            <td
              :class="{
                positive: sumItem.sumCommissionAmount > 0,
                negative: sumItem.sumCommissionAmount < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sumCommissionAmount) }}
            </td>
            <td
              :class="{
                positive: sumItem.sumWinAmount > 0,
                negative: sumItem.sumWinAmount < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sumWinAmount) }}
            </td>
            <td
              :class="{
                positive: sumItem.sum > 0,
                negative: sumItem.sum < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sum) }}
            </td>
          </tr>
        </tfoot>
      </template>
    </CDataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "ตลาด",
        },
        {
          key: "sumBuyAmount",
          label: "ยอดแทง",
        },
        {
          key: "sumCommissionAmount",
          label: "ส่วนลด",
        },
        {
          key: "sumWinAmount",
          label: "ถูกรางวัล",
        },
        {
          key: "sum",
          label: "รวม",
        },
      ],
      items: [],
      sumItem: {},
      isLoading: true,
      fromDate: this.$date().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
    }
  },
  methods: {
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.loadData()
    },
    getClassAmount(amount) {
      if (parseFloat(amount) > 0) {
        return "positive"
      } else if (parseFloat(amount) < 0) {
        return "negative"
      }
      return ""
    },
    async loadData() {
      this.swal.processing()

      try {
        let res = await this.axios({
          method: "get",
          url: "report/by-date",
          params: {
            fromDate: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            toDate: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        })

        let data = res.data.data

        let sumItem = {
          sumBuyAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumWinAmount: 0.0,
          sum: 0.0,
        }

        data?.forEach((item) => {
          item.sum =
            parseFloat(item.sumBuyAmount * -1) +
            parseFloat(item.sumWinAmount) +
            parseFloat(item.sumCommissionAmount)

          sumItem.sumBuyAmount += parseFloat(item.sumBuyAmount * -1)
          sumItem.sumWinAmount += parseFloat(item.sumWinAmount)
          sumItem.sumCommissionAmount += parseFloat(item.sumCommissionAmount)
          sumItem.sum += parseFloat(item.sum)

          item.sum = this.util.formatMoney(item.sum)

          item.sumBuyAmount = this.util.formatMoney(item.sumBuyAmount * -1)
          item.sumCommissionAmount = this.util.formatMoney(
            item.sumCommissionAmount
          )
          item.sumWinAmount = this.util.formatMoney(item.sumWinAmount)

          item.name = item.typeName + " " + item.roundName

          item._cellClasses = {
            sumBuyAmount: this.getClassAmount(item.sumBuyAmount),
            sumCommissionAmount: this.getClassAmount(item.sumCommissionAmount),
            sumWinAmount: this.getClassAmount(item.sumWinAmount),
            sum: this.getClassAmount(item.sum),
          }
        })

        this.items = data
        this.sumItem = sumItem

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  async created() {
    await this.loadData()
  },
}
</script>