<template>
  <div>
    <div
      class="card border-primary bg-transparent mb-4"
      style="width: 700px"
      v-if="!isBillPage"
    >
      <h6 class="card-header border-primary bg-primary text-white p-2">
        ตัวเลือกการค้นหา
      </h6>
      <div class="card-body">
        <button
          class="btn mr-1"
          :class="[itemStatus == 'ALL' ? 'btn-primary' : 'btn-outline-primary']"
          @click="itemStatus = 'ALL'"
        >
          ทั้งหมด
        </button>
        <button
          class="btn mr-1"
          :class="[
            itemStatus == 'BET_WIN' ? 'btn-success' : 'btn-outline-success',
          ]"
          @click="itemStatus = 'BET_WIN'"
        >
          ถูกรางวัล
        </button>
        <button
          class="btn mr-1"
          :class="[
            itemStatus == 'BET_LOSE' ? 'btn-danger' : 'btn-outline-danger',
          ]"
          @click="itemStatus = 'BET_LOSE'"
        >
          ไม่ถูกรางวัล
        </button>
      </div>
    </div>
    <CDataTable
      :items="isBillPage ? items : itemsFilter"
      :fields="isBillPage ? fields : fieldsReportByRound"
      :items-per-page="20"
      :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }"
      column-filter
      :table-filter="{ label: 'ค้นหา', placeholder: 'ค้นหา' }"
      :items-per-page-select="{ label: 'จำนวนแถว' }"
      pagination
      dark
      border
      size="sm"
      addTableClasses="w-100 text-center text-nowrap"
    >
      <template #showItem="{ item }">
        <td>
          <button class="btn btn-sm btn-primary" @click="toggleDetails(item)">
            <i class="fas fa-plus"></i>
          </button>
        </td>
      </template>
      <template #details="{ item }">
        <CCollapse :show="Boolean(item._toggled)" :duration="0">
          <CCardBody v-if="item.detail" class="bg-secondary d-flex flex-column">
            <table
              class="
                table table-bordered table-dark table-striped
                text-center
                mb-0
                w-100
              "
            >
              <thead>
                <tr class="bg-primary">
                  <th :colspan="isBillPage ? 5 : 6">
                    <div
                      class="d-flex justify-content-center position-relative"
                      style="height: 30px"
                    >
                      <span class="h6 my-auto">
                        เลขที่บิล #{{ item.id }} {{ item.name }}
                      </span>
                      <button
                        style="position: absolute; right: 10px"
                        class="btn btn-sm btn-danger"
                        v-if="item.status == 'OPEN'"
                        @click="cancel(item)"
                      >
                        ยกเลิกบิล
                      </button>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>ประเภท @ หมายเลข</th>
                  <th>เรทจ่าย</th>
                  <th>ยอดเดิมพัน</th>
                  <th>ส่วนลด</th>
                  <th>
                    {{
                      item.status == "BET_WIN" || item.status == "BET_LOSE"
                        ? "ถูกรางวัล"
                        : "เรทชนะ"
                    }}
                  </th>
                  <th v-if="!isBillPage">สถานะ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="billItem in item.detail.billItems"
                  :key="billItem.id"
                >
                  <td>{{ billItem.name }} @ {{ billItem.number }}</td>
                  <td>{{ util.formatMoney(billItem.payRate) }}</td>
                  <td>{{ util.formatMoney(billItem.buyAmount) }}</td>
                  <td>{{ util.formatMoney(billItem.commissionAmount) }}</td>
                  <td :class="{ positive: billItem.winAmount > 0 }">
                    {{ util.formatMoney(billItem.winAmount) }}
                  </td>
                  <td
                    v-if="!isBillPage"
                    :class="{
                      positive: billItem.status == 'BET_WIN',
                      negative: billItem.status == 'BET_LOSE',
                    }"
                  >
                    {{ billItem.statusTH }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <td colspan="2">{{ item.detail.billItems.length }} รายการ</td>
                <td>{{ util.formatMoney(item.detail.sumBuyAmount) }}</td>
                <td>{{ util.formatMoney(item.detail.sumCommissionAmount) }}</td>
                <td :class="{ positive: item.detail.sumWinAmount > 0 }">
                  {{ util.formatMoney(item.detail.sumWinAmount) }}
                </td>
                <td v-if="!isBillPage"></td>
              </tfoot>
            </table>
          </CCardBody>
        </CCollapse>
      </template>
      <template v-slot:footer>
        <tfoot>
          <tr>
            <td colspan="6">รวม:</td>
            <td
              :class="{
                positive: sumItem.sumBuyAmount > 0,
                negative: sumItem.sumBuyAmount < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sumBuyAmount) }}
            </td>
            <td
              :class="{
                positive: sumItem.sumCommissionAmount > 0,
                negative: sumItem.sumCommissionAmount < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sumCommissionAmount) }}
            </td>
            <td
              v-if="!isBillPage"
              :class="{
                positive: sumItem.sumWinAmount > 0,
                negative: sumItem.sumWinAmount < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sumWinAmount) }}
            </td>
            <td
              v-if="!isBillPage"
              :class="{
                positive: sumItem.sum > 0,
                negative: sumItem.sum < 0,
              }"
            >
              {{ util.formatMoney(sumItem.sum) }}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </template>
    </CDataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "showItem",
          label: "ดูบิล",
          filter: false,
          _style: "width: 50px",
        },
        {
          key: "createdAt",
          label: "เวลาแทง",
        },
        {
          key: "name",
          label: "ตลาด",
        },
        {
          key: "note",
          label: "หมายเหตุ",
        },
        {
          key: "numberOfItem",
          label: "รายการ",
        },
        {
          key: "sumBuyAmount",
          label: "ยอดแทง",
        },
        {
          key: "sumCommissionAmount",
          label: "ส่วนลด",
        },
        {
          key: "statusTH",
          label: "สถานะ",
        },
      ],
      fieldsReportByRound: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "showItem",
          label: "ดูบิล",
          filter: false,
          _style: "width: 50px",
        },
        {
          key: "createdAt",
          label: "เวลาแทง",
        },
        {
          key: "name",
          label: "ตลาด",
        },
        {
          key: "note",
          label: "หมายเหตุ",
        },
        {
          key: "numberOfItem",
          label: "รายการ",
        },
        {
          key: "showSumBuyAmount",
          label: "ยอดแทง",
        },
        {
          key: "showSumCommissionAmount",
          label: "ส่วนลด",
        },
        {
          key: "showSumWinAmount",
          label: "ถูกรางวัล",
        },
        {
          key: "showSum",
          label: "ได้เสีย",
        },
        {
          key: "statusTH",
          label: "สถานะ",
        },
      ],
      sumItem: {},
      items: [],
      isLoading: true,
      isBillPage: this.$route.name == "Bill",
      itemStatus: "ALL",
    }
  },
  computed: {
    itemsFilter() {
      let itemsFilter = this.items.filter(
        (item) => this.itemStatus == "ALL" || item.status == this.itemStatus
      )
      this.makeSumItem(itemsFilter)
      return itemsFilter
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("forceReloadView")
    },
  },
  methods: {
    getClassAmount(amount) {
      if (parseFloat(amount) > 0) {
        return "positive"
      } else if (parseFloat(amount) < 0) {
        return "negative"
      }
      return ""
    },
    async cancel(item) {
      let detail = item.detail

      // if (detail.numberOfCancelBill <= 0) {
      //   this.$swal({
      //     text: "ท่านคืนโพยครบจำนวนแล้วในรอบนี้",
      //     icon: "warning",
      //   });
      //   return;
      // }

      if (!detail.cancelable) {
        // this.$swal({
        //   text: `ท่านสามารถคืนโพยหวยได้ก่อนงวดหวยปิด ${detail.cancelMinuteBeforeClose} นาที เท่านั้น`,
        //   icon: "warning",
        // })
        this.$swal({
          text: `ไม่สามารถยกเลิกบิลได้ เนื่องจากงวดหวยปิดแล้ว`,
          icon: "warning",
        })
        return
      }

      // let result = await this.swal.confirm(
      //   `ท่านสามารถคืนโพยได้อีก ${detail.numberOfCancelBill} ครั้ง`
      // );
      // if (!result) {
      //   return;
      // }

      this.swal.processing()
      try {
        await this.axios({
          method: "patch",
          url: "users/bill/cancelled/" + item.id,
        })

        await this.$swal({
          icon: "success",
          text: "คืนโพยสำเร็จ",
        })

        this.$store.dispatch("forceReloadView")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    makeSumItem(data) {
      let sumItem = {
        sumBuyAmount: 0.0,
        sumCommissionAmount: 0.0,
        sumWinAmount: 0.0,
        sum: 0.0,
      }

      data?.forEach((item) => {
        item.sum =
          parseFloat(item.sumBuyAmount * -1) +
          parseFloat(item.sumWinAmount) +
          parseFloat(item.sumCommissionAmount)

        if (item.status != "CANCELLED_BILL") {
          sumItem.sumBuyAmount += parseFloat(item.sumBuyAmount * -1)
          sumItem.sumCommissionAmount += parseFloat(item.sumCommissionAmount)
          sumItem.sumWinAmount += parseFloat(item.sumWinAmount)
          sumItem.sum += parseFloat(item.sum)
        }

        item.showSumBuyAmount = this.util.formatMoney(item.sumBuyAmount * -1)
        item.showSumCommissionAmount = this.util.formatMoney(
          item.sumCommissionAmount
        )
        item.showSumWinAmount = this.util.formatMoney(item.sumWinAmount)
        item.showSum = this.util.formatMoney(item.sum)

        item._cellClasses = {
          showSumBuyAmount: this.getClassAmount(item.sumBuyAmount * -1),
          showSumCommissionAmount: this.getClassAmount(
            item.sumCommissionAmount
          ),
          showSumWinAmount: this.getClassAmount(item.sumWinAmount),
          showSum: this.getClassAmount(item.sum),
          statusTH: this.getStatusClass(item.status),
        }
      })

      this.sumItem = sumItem
    },
    async toggleDetails(item) {
      this.$set(item, "_toggled", !item._toggled)

      if (item.detail) {
        return
      }

      try {
        let res = await this.axios({
          method: "get",
          url: "users/bill/" + item.id,
        })

        let data = res.data.data
        data.sumBuyAmount = 0.0
        data.sumCommissionAmount = 0.0
        data.sumWinAmount = 0.0

        data.billItems.forEach((item) => {
          data.sumBuyAmount += parseFloat(item.buyAmount)
          data.sumCommissionAmount += parseFloat(item.commissionAmount)
          item.winAmount =
            item.status == "OPEN"
              ? item.buyAmount * item.payRate
              : item.winAmount
          data.sumWinAmount += parseFloat(item.winAmount)
        })

        this.$set(item, "detail", data)
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadData() {
      this.swal.processing()

      try {
        let res = await this.axios({
          method: "get",
          url: "users/bill",
        })

        let data = res.data.data
        let sumItem = {
          sumBuyAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumWinAmount: 0.0,
          sum: 0.0,
        }

        data?.forEach((item) => {
          if (item.status != "CANCELLED_BILL") {
            sumItem.sumBuyAmount += parseFloat(item.sumBuyAmount)
            sumItem.sumCommissionAmount += parseFloat(item.sumCommissionAmount)
          }

          item.numberOfItem = this.util.formatMoney(item.numberOfItem)
          item.sumBuyAmount = this.util.formatMoney(item.sumBuyAmount)
          item.sumCommissionAmount = this.util.formatMoney(
            item.sumCommissionAmount
          )

          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )

          item._cellClasses = {
            sumBuyAmount: "positive",
            sumCommissionAmount: "positive",
          }
        })

        this.sumItem = sumItem
        this.items = data

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadDataReportByRound() {
      this.swal.processing()

      try {
        let res = await this.axios({
          method: "get",
          url: "report/by-round",
          params: {
            roundId: this.$route.params.roundId,
          },
        })

        let data = res.data.data

        data?.forEach((item) => {
          item.name = item.typeName + " " + item.roundName
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
        })

        this.makeSumItem(data)
        this.items = data

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    getStatusClass(status) {
      if (status == "BET_WIN") {
        return "positive"
      } else if (["BET_LOSE", "CANCELLED_BILL_BYWEB"].includes(status)) {
        return "negative"
      }

      return ""
    },
  },
  mounted() {
    this.isBillPage ? this.loadData() : this.loadDataReportByRound()
  },
}
</script>