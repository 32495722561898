import { extend, setInteractionMode } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

export default {
  setupValidate() {
    setInteractionMode("lazy");

    extend("min_value", {
      ...rules.min_value,
      message: "ต้องไม่ต่ำกว่า {min}"
    });

    extend("max_value", {
      ...rules.max_value,
      message: "ต้องไม่มากกว่า {max}"
    });

    extend("max", {
      ...rules.max,
      message: `ต้องไม่มากกว่า {length} อักษร`
    });

    extend("is", {
      ...rules.is,
      message: "ไม่ถูกต้อง"
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1
        };
      },
      computesRequired: true,
      message: "ต้องไม่เป็นค่าว่าง"
    });

    extend("alpha_num", {
      ...rules.alpha_num,
      message: "ต้องเป็นตัวอักษรหรือตัวเลขเท่านั้น"
    });

    extend("numeric", {
      ...rules.numeric,
      message: "ต้องเป็นตัวเลขเท่านั้น"
    });

    extend("alpha", {
      ...rules.alpha,
      message: "ต้องเป็นตัวอักษรเท่านั้น"
    });

    extend("min", {
      ...rules.min,
      message: "ต้องไม่ต่ำกว่า {length} อักษร"
    });

    extend("password_confirm", {
      params: ["target"],
      validate(value, { target }) {
        return value === target;
      },
      message: "รหัสผ่านไม่ตรงกัน"
    });

    Object.keys(rules).forEach((rule) => {
      extend(rule, rules[rule]);
    });
  }
};
