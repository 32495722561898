var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isBillPage)?_c('div',{staticClass:"card border-primary bg-transparent mb-4",staticStyle:{"width":"700px"}},[_c('h6',{staticClass:"card-header border-primary bg-primary text-white p-2"},[_vm._v(" ตัวเลือกการค้นหา ")]),_c('div',{staticClass:"card-body"},[_c('button',{staticClass:"btn mr-1",class:[_vm.itemStatus == 'ALL' ? 'btn-primary' : 'btn-outline-primary'],on:{"click":function($event){_vm.itemStatus = 'ALL'}}},[_vm._v(" ทั้งหมด ")]),_c('button',{staticClass:"btn mr-1",class:[
          _vm.itemStatus == 'BET_WIN' ? 'btn-success' : 'btn-outline-success' ],on:{"click":function($event){_vm.itemStatus = 'BET_WIN'}}},[_vm._v(" ถูกรางวัล ")]),_c('button',{staticClass:"btn mr-1",class:[
          _vm.itemStatus == 'BET_LOSE' ? 'btn-danger' : 'btn-outline-danger' ],on:{"click":function($event){_vm.itemStatus = 'BET_LOSE'}}},[_vm._v(" ไม่ถูกรางวัล ")])])]):_vm._e(),_c('CDataTable',{attrs:{"items":_vm.isBillPage ? _vm.items : _vm.itemsFilter,"fields":_vm.isBillPage ? _vm.fields : _vm.fieldsReportByRound,"items-per-page":20,"noItemsView":{
      noResults: 'ไม่พบข้อมูล',
      noItems: 'ไม่พบข้อมูล',
    },"column-filter":"","table-filter":{ label: 'ค้นหา', placeholder: 'ค้นหา' },"items-per-page-select":{ label: 'จำนวนแถว' },"pagination":"","dark":"","border":"","size":"sm","addTableClasses":"w-100 text-center text-nowrap"},scopedSlots:_vm._u([{key:"showItem",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){return _vm.toggleDetails(item)}}},[_c('i',{staticClass:"fas fa-plus"})])])]}},{key:"details",fn:function(ref){
    var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":0}},[(item.detail)?_c('CCardBody',{staticClass:"bg-secondary d-flex flex-column"},[_c('table',{staticClass:"\n              table table-bordered table-dark table-striped\n              text-center\n              mb-0\n              w-100\n            "},[_c('thead',[_c('tr',{staticClass:"bg-primary"},[_c('th',{attrs:{"colspan":_vm.isBillPage ? 5 : 6}},[_c('div',{staticClass:"d-flex justify-content-center position-relative",staticStyle:{"height":"30px"}},[_c('span',{staticClass:"h6 my-auto"},[_vm._v(" เลขที่บิล #"+_vm._s(item.id)+" "+_vm._s(item.name)+" ")]),(item.status == 'OPEN')?_c('button',{staticClass:"btn btn-sm btn-danger",staticStyle:{"position":"absolute","right":"10px"},on:{"click":function($event){return _vm.cancel(item)}}},[_vm._v(" ยกเลิกบิล ")]):_vm._e()])])]),_c('tr',[_c('th',[_vm._v("ประเภท @ หมายเลข")]),_c('th',[_vm._v("เรทจ่าย")]),_c('th',[_vm._v("ยอดเดิมพัน")]),_c('th',[_vm._v("ส่วนลด")]),_c('th',[_vm._v(" "+_vm._s(item.status == "BET_WIN" || item.status == "BET_LOSE" ? "ถูกรางวัล" : "เรทชนะ")+" ")]),(!_vm.isBillPage)?_c('th',[_vm._v("สถานะ")]):_vm._e()])]),_c('tbody',_vm._l((item.detail.billItems),function(billItem){return _c('tr',{key:billItem.id},[_c('td',[_vm._v(_vm._s(billItem.name)+" @ "+_vm._s(billItem.number))]),_c('td',[_vm._v(_vm._s(_vm.util.formatMoney(billItem.payRate)))]),_c('td',[_vm._v(_vm._s(_vm.util.formatMoney(billItem.buyAmount)))]),_c('td',[_vm._v(_vm._s(_vm.util.formatMoney(billItem.commissionAmount)))]),_c('td',{class:{ positive: billItem.winAmount > 0 }},[_vm._v(" "+_vm._s(_vm.util.formatMoney(billItem.winAmount))+" ")]),(!_vm.isBillPage)?_c('td',{class:{
                    positive: billItem.status == 'BET_WIN',
                    negative: billItem.status == 'BET_LOSE',
                  }},[_vm._v(" "+_vm._s(billItem.statusTH)+" ")]):_vm._e()])}),0),_c('tfoot',[_c('td',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(item.detail.billItems.length)+" รายการ")]),_c('td',[_vm._v(_vm._s(_vm.util.formatMoney(item.detail.sumBuyAmount)))]),_c('td',[_vm._v(_vm._s(_vm.util.formatMoney(item.detail.sumCommissionAmount)))]),_c('td',{class:{ positive: item.detail.sumWinAmount > 0 }},[_vm._v(" "+_vm._s(_vm.util.formatMoney(item.detail.sumWinAmount))+" ")]),(!_vm.isBillPage)?_c('td'):_vm._e()])])]):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_vm._v("รวม:")]),_c('td',{class:{
              positive: _vm.sumItem.sumBuyAmount > 0,
              negative: _vm.sumItem.sumBuyAmount < 0,
            }},[_vm._v(" "+_vm._s(_vm.util.formatMoney(_vm.sumItem.sumBuyAmount))+" ")]),_c('td',{class:{
              positive: _vm.sumItem.sumCommissionAmount > 0,
              negative: _vm.sumItem.sumCommissionAmount < 0,
            }},[_vm._v(" "+_vm._s(_vm.util.formatMoney(_vm.sumItem.sumCommissionAmount))+" ")]),(!_vm.isBillPage)?_c('td',{class:{
              positive: _vm.sumItem.sumWinAmount > 0,
              negative: _vm.sumItem.sumWinAmount < 0,
            }},[_vm._v(" "+_vm._s(_vm.util.formatMoney(_vm.sumItem.sumWinAmount))+" ")]):_vm._e(),(!_vm.isBillPage)?_c('td',{class:{
              positive: _vm.sumItem.sum > 0,
              negative: _vm.sumItem.sum < 0,
            }},[_vm._v(" "+_vm._s(_vm.util.formatMoney(_vm.sumItem.sum))+" ")]):_vm._e(),_c('td')])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }