import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));

export default {
  namespaced: true,
  state: {
    status: {
      loggedIn: user ? true : false
    },
    user: user
  },
  actions: {
    async login({ commit }, user) {
      return axios
        .post("auth/login", {
          username: user.username,
          password: user.password
        })
        .then((response) => {
          if (response.data.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
          }
          commit("loginSuccess", response.data.data);
          return Promise.resolve();
        })
        .catch((error) => {
          commit("loginFailure");
          return Promise.reject(error);
        });
    },
    async updateUserData({ commit }) {
      return axios
        .get("users/")
        .then((response) => {
          commit("updateUserData", response.data.data);
          return Promise.resolve();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout({ commit }) {
      localStorage.removeItem("user");
      commit("logout");
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    updateUserData(state, data) {
      state.user.userData = data;
    }
  }
};
